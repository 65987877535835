import { get, post, put, patch, generateQueryParams, _delete } from '../fetch.js';

export const getVerificationsOfEntity = (entityType, entityId) =>
   get(`/verification-progress/${entityType}/${entityId}`);

export const updateVerificationOfEntityById = (id, fields) =>
   put(
      `/verification/${id}`,
      {
         ...fields
      },
      true
   );

import { get, post, put, generateQueryParams, _delete } from '../fetch.js';

export const getStoryById = (id) => get(`/story/${id}`);

export const deleteStoryById = (id) => _delete(`/story/${id}`, {}, true);

export const getStories = (params) => get(generateQueryParams(`/stories`, params));

export const getStoriesByTag = (tag) => get(`/storytags/${tag}`);

export const createStory = (fields) =>
   post(
      '/story',
      {
         ...fields
      },
      true
   );

export const updateStory = (fields, id) =>
   put(
      `/story/${id}`,
      {
         ...fields
      },
      true
   );

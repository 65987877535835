import { Tooltip } from 'antd';

function getEntityName(e) {
   return `${e?.lastName || ''} ${e?.firstName || ''}`;
}

function getEntityNameWithBR(e) {
   return (
      <>
         {e?.lastName || ''} <br /> {e?.firstName || ''}
      </>
   );
}

const getMessageText = (type, message) => {
   if (type === 'text') {
      return (
         <Tooltip placement="top" title={'Заметка'} arrow={false}>
            <div className="note-message">{message}</div>
         </Tooltip>
      );
   }

   if (type === 'opros') {
      const all = [];

      message.forEach((e, i) => {
         all.push(
            <>
               {' '}
               <Tooltip placement="top" title={'Вопрос'} arrow={false}>
                  <div
                     key={i}
                     className="note-message"
                     style={{
                        background: '#e1ff3c45'
                     }}>
                     {e.opros}
                  </div>{' '}
               </Tooltip>{' '}
               <Tooltip placement="top" title={'Ответ'} arrow={false}>
                  <div
                     key={i}
                     className="note-message"
                     style={{ background: '#ffffff' }}>
                     {e.otvet}
                  </div>{' '}
               </Tooltip>
            </>
         );
      });

      return all;
   }
};

function formatPhoneNumber(number) {
   const strNumber = number.toString();

   if (strNumber.length !== 10) {
      return number;
   }

   const countryCode = '+7';
   const areaCode = strNumber.slice(0, 3);
   const firstPart = strNumber.slice(3, 6);
   const secondPart = strNumber.slice(6, 8);
   const thirdPart = strNumber.slice(8, 10);

   const formattedNumber = `${countryCode} ${areaCode} ${firstPart}-${secondPart}-${thirdPart}`;

   return formattedNumber;
}

export { getEntityName, getEntityNameWithBR, formatPhoneNumber, getMessageText };

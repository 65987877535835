import React, { useState, useEffect } from 'react';
import { Space, Table, Tag, Tooltip, Spin, Dropdown } from 'antd';
import { Button, DateWithAgo } from '@shared/ui';
import {
   EditOutlined,
   StarFilled,
   DashOutlined,
   ExportOutlined,
   UnorderedListOutlined,
   DollarOutlined,
   BarsOutlined,
   StarOutlined
} from '@ant-design/icons';
import Pagination, { initialPaginationSettings } from '@widgets/Pagination';
import { GetNoteListExcel } from '../model/GetNoteListExcel';
import { Typography } from 'antd';
import { VerticalSpace, StatusTag } from '@shared/ui';
import TotalCountTableText from '@widgets/Table/TotalCountTableText';
import ExportToCSVModalButton from '@widgets/ExportToCSV/ExportToCSVModalButton';
import { GetNoteList } from '../model/GetNoteList';
// import { GetLeadListExcel } from '@shared/api/GetLeadListExcel';
// import { GetLeadStatusesListExcel } from '@shared/api/GetLeadStatusesListExcel';

import SettingIcon from '@shared/assets/icons/SettingIcon';
import {
   getColumnSearchProps,
   onSearchFilterTable,
   onTableChange
} from '@shared/ui/Table';
import { formatPhoneNumber } from '@shared/utils/entity';

import { timestampToNormalDDMMYY_HHMM } from '@shared/utils/tsToTime';
import { useNavigate } from 'react-router-dom';
import { isHaveUrlListSettings, parseUrlListSettings } from '@shared/utils/browser';
import { getApiQueryParamsForTables } from '@shared/lib/table';
import { changeUrl } from '@shared/utils/browser';
import { getMessageText } from '@shared/utils/entity';

const { Text } = Typography;

const initialSorter = {
   sort: 'id',
   order: 'desc'
};

const TableNotes = () => {
   const [isLoading, setIsLoading] = useState(false);
   const [data, setData] = useState([]);
   const [selectedData, setSelectedData] = useState(null);
   const navigate = useNavigate();

   const [paramsTable, setParamsTable] = useState({
      pagination: { ...initialPaginationSettings() },
      filters: {},
      sorter: initialSorter
   });

   const { pagination, filters } = paramsTable;

   useEffect(() => {
      if (isHaveUrlListSettings) {
         const params = parseUrlListSettings();

         fetchData(params);
      }
   }, []);

   const columns = [
      {
         title: 'ID',
         dataIndex: 'id',
         key: 'id',
         sorter: true,
         sortOrder:
            paramsTable?.sorter?.sort === 'id'
               ? `${paramsTable?.sorter?.order}end`
               : null,
         filtered: !!filters?.['id'],
         width: '120px',
         filteredValue: filters?.['id'] || null,
         ...getColumnSearchProps({
            dataIndex: 'id',
            handleSearch: (searchObj) => onSearchTable(searchObj),
            type: 'number'
         }),
         render: (_, record) => _
      },
      {
         title: 'Дата',
         dataIndex: 'createdAt',
         key: 'createdAt',
         sorter: true,
         width: '160px',
         sortOrder:
            paramsTable?.sorter?.sort === 'createdAt'
               ? `${paramsTable?.sorter?.order}end`
               : null,
         render: (_) => <DateWithAgo date={_} showDate={true} />
      },
      {
         title: 'У кого',
         dataIndex: 'entityType',
         key: 'entityType',
         render: (_) => (_ === 'driver' ? 'Водитель' : 'Продавец')
      },
      {
         title: 'У кого',
         dataIndex: 'entityId',
         key: 'entityId',
         render: (_, record) => (
            <Tooltip placement="top" title={'Открыть'}>
               <span
                  className="green-span-url"
                  style={{ padding: '5px 0', whiteSpace: 'nowrap' }}
                  onClick={() =>
                     navigate(
                        `/${
                           record.entityType === 'driver' ? 'drivers' : 'sellers'
                        }/${record.entityId}`
                     )
                  }>
                  {`${record.entityType === 'driver' ? 'Водитель' : 'Продавец'} №${
                     record.entityId
                  }`}
               </span>
            </Tooltip>
         )
      },
      {
         title: 'Опрос',
         dataIndex: 'message',
         key: 'message',
         render: (_, record) => getMessageText(record.messageType, record.message)
      }
   ];

   const actions = [];

   const onSearchTable = (searchObj) => {
      onSearchFilterTable(searchObj, paramsTable, fetchData);
   };

   const handleTableChange = (pagination, filtersTable, sorterTable) => {
      onTableChange(pagination, filtersTable, sorterTable, paramsTable, fetchData);
   };

   const closeModal = () => {
      setSelectedData(null);

      setTimeout(() => {
         fetchData(paramsTable);
      }, 1000);
   };

   const fetchData = (params = paramsTable) => {
      setIsLoading(true);

      const queryObj = getApiQueryParamsForTables(
         params,
         paramsTable,
         initialSorter
      );
      const { page: current, limit: pageSize } = queryObj;

      GetNoteList({ ...queryObj, messageType: 'opros', status: 'active' }).then(
         (res) => {
            setParamsTable({
               ...params,
               pagination: {
                  ...params.pagination,
                  current,
                  pageSize,
                  total: res.count
               }
            });

            changeUrl(params);
            setData(res.data);
            setIsLoading(false);
         }
      );
   };

   const onChangePagination = (current, pageSize) => {
      const newParams = {
         ...paramsTable,
         pagination: { ...paramsTable.pagination, current, pageSize }
      };
      fetchData(newParams);
   };

   const closeCreate = () => {
      setSelectedData(null);

      setTimeout(() => {
         fetchData(paramsTable);
      }, 1000);
   };

   return (
      <>
         <div className="table-right-block-top">
            <div className="table-right-block-top_item">
               <Space size="small">
                  <TotalCountTableText total={pagination.total} />
                  <ExportToCSVModalButton
                     fetchData={GetNoteListExcel}
                     dataParams={paramsTable}
                  />
               </Space>
            </div>
         </div>

         <VerticalSpace />
         <div className="table-block">
            <Table
               rowKey="id"
               columns={[...columns, ...actions]}
               dataSource={data}
               pagination={false}
               onChange={handleTableChange}
               loading={isLoading}
               scroll={{ x: 1500 }}
            />
         </div>

         <VerticalSpace />
         <Space style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {!!pagination.total && (
               <Pagination
                  pagination={pagination}
                  onChangePagination={onChangePagination}
                  isLoading={isLoading}
               />
            )}
         </Space>
      </>
   );
};

export default TableNotes;

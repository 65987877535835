/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Select } from '@shared/ui';
import {
   Col,
   Row,
   Alert,
   DatePicker,
   Radio,
   Space,
   Divider,
   message,
   Tag
} from 'antd';
import { statusesOfNotes } from '@shared/const/statuses';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { VerticalSpace } from '@shared/ui';

import { GetNoteCauses } from '../model/GetNoteCauses';
const { TextArea } = Input;

const disabledDateTime = () => ({
   disabledHours: () => [0, 1, 2, 3, 4, 5]
});

const allTags = {
   'Откуда о нас узнали': [
      'порекомендовали коллеги',
      'к нам приезжал менеджер',
      'сайт Экориум',
      'объявления на авито',
      'видели рекламу в интернете',
      'другое (комментарий)'
   ],
   'Как часто копится объем': [
      'Разово',
      '1-2 раза в неделю',
      '1 раз в 2 недели',
      '1 раз в месяц',
      '1 раз в 3 месяца',
      '1 раз в полгода',
      '1 раз в год'
   ],
   'Какой объем копится': [
      'До 50кг',
      '50-100кг',
      '100-200кг',
      '200-350кг',
      '350-500кг',
      'более 500кг'
   ],
   'Оценка вывозов с Экориум (выбор значения от 1 до 10)': [
      '1 - нужен комментарий',
      '2 - нужен комментарий',
      '3 - нужен комментарий',
      '4 - нужен комментарий',
      '5 - нужен комментарий',
      '6 - нужен комментарий',
      '7',
      '8',
      '9',
      '10'
   ],
   'Какое сырье у вас образуется?': [
      'Картон',
      'Архив',
      'Сборка',
      'Книги',
      'Газеты',
      'Пластиковые ведерки',
      'Пластик: крышечки',
      'Пластиковые продуктовые ящики',
      'Поддоны / паллеты деревянные',
      'Масло отработанное (машинное)',
      'Масло фритюрное (производство, кафе)',
      'Черный металл: чермет (трубы, старая посуда, тележки)',
      'Черный металл: Чугун (ванная, посуда)'
   ]
};

function hasDuplicateOpros(data) {
   const seen = new Set();
   for (const item of data) {
      if (seen.has(item.opros)) {
         return true;
      }
      seen.add(item.opros);
   }
   return false;
}

const NoteForm = (props) => {
   const [isLoading, setIsLoading] = useState(false);
   const [causes, setCauses] = useState([]);
   const { initialValues, onSuccess, isEditForm, errorMessage, entityType } = props;
   const [form] = Form.useForm();

   useEffect(() => {
      fetchCauses();
   }, []);

   const fetchCauses = async () => {
      GetNoteCauses().then((res) => {
         setCauses(res?.data || []);
      });
   };

   const onFinish = (values) => {
      if (
         values.messageType === 'opros' ||
         (!values.messageType &&
            initialValues &&
            initialValues.messageType === 'opros')
      ) {
         if (hasDuplicateOpros(values.message)) {
            return message.error('Одинаковые вопросы не допускаются');
         }
      }

      onSuccess(values, setIsLoading).then(() => {
         if (isEditForm) {
            return;
         }
      });
   };

   const onFinishFailed = (errorInfo) => {
      setIsLoading(false);
      console.log('Failed:', errorInfo);
   };

   const modifiedInitialValues = {
      status: statusesOfNotes.active.value,
      messageType: 'text',
      ...initialValues,
      noteTs: initialValues?.noteTs ? dayjs(initialValues?.noteTs) : dayjs()
   };

   return (
      <Form
         name="basic"
         style={{
            minWidth: 320
         }}
         form={form}
         onFinish={onFinish}
         initialValues={modifiedInitialValues}
         onFinishFailed={onFinishFailed}
         hideRequiredMark
         layout="vertical">
         {() => (
            <>
               <Row gutter={24}>
                  <Col span={24}>
                     {isEditForm ? (
                        <></>
                     ) : (
                        <>
                           {['driver', 'seller'].includes(entityType) && (
                              <Form.Item
                                 name="messageType"
                                 label="Выберите тип заметки"
                                 rules={[
                                    {
                                       required: true,
                                       message: 'Выберите тип'
                                    }
                                 ]}>
                                 <Radio.Group buttonStyle="solid">
                                    <Radio.Button value="text">Текст</Radio.Button>
                                    <Radio.Button value="opros">Опрос</Radio.Button>
                                 </Radio.Group>
                              </Form.Item>
                           )}
                        </>
                     )}

                     {form.getFieldValue('messageType') === 'text' && (
                        <Form.Item
                           name="cause"
                           label="Причина заметки"
                           rules={[
                              {
                                 required: true,
                                 message: 'Поле не может быть пустым '
                              }
                           ]}>
                           <Select
                              allowClear
                              showSearch
                              style={{
                                 width: '100%'
                              }}
                              placeholder="Выберите причину"
                              options={Object.values(causes).map((e) => ({
                                 label: e,
                                 value: e
                              }))}
                           />
                        </Form.Item>
                     )}

                     <Form.Item
                        name="noteTs"
                        label={
                           form.getFieldValue('messageType') === 'opros'
                              ? 'Дата опроса'
                              : 'Дата заметки'
                        }
                        rules={[
                           {
                              required: true,
                              message: 'Поле не может быть пустым '
                           }
                        ]}>
                        <DatePicker
                           showTime
                           style={{ width: '100%' }}
                           format="YYYY-MM-DD HH:mm"
                           disabledTime={disabledDateTime}
                        />
                     </Form.Item>

                     {form.getFieldValue('messageType') === 'opros' && (
                        <>
                           <Divider>Вопросы ответы</Divider>
                           <Form.List name="message">
                              {(fields, { add, remove }) => (
                                 <>
                                    {fields.map(({ key, name, ...restField }) => (
                                       <Row gutter={24}>
                                          <Col span={19}>
                                             <Form.Item
                                                {...restField}
                                                label={`Вопрос ${key + 1}`}
                                                name={[name, 'opros']}
                                                rules={[
                                                   {
                                                      required: true,
                                                      message:
                                                         'Поле не может быть пустым '
                                                   }
                                                ]}>
                                                <Select
                                                   style={{
                                                      width: '100%'
                                                   }}
                                                   placeholder="Выберите опрос"
                                                   rules={[
                                                      {
                                                         required: true,
                                                         message:
                                                            'Поле не может быть пустым '
                                                      }
                                                   ]}
                                                   options={Object.keys(allTags).map(
                                                      (e) => ({
                                                         label: e,
                                                         value: e
                                                      })
                                                   )}
                                                />
                                             </Form.Item>{' '}
                                          </Col>
                                          <Col span={5}>
                                             <VerticalSpace />
                                             <VerticalSpace />
                                             <Button
                                                type="primary"
                                                danger
                                                onClick={() => remove(name)}>
                                                Удалить
                                             </Button>
                                          </Col>

                                          <Col span={24}>
                                             <Form.Item
                                                {...restField}
                                                name={[name, 'otvet']}
                                                label="Ответ"
                                                rules={[
                                                   {
                                                      required: true,
                                                      message:
                                                         'Поле не может быть пустым '
                                                   }
                                                ]}>
                                                <TextArea
                                                   rows={2}
                                                   placeholder="Ответ"
                                                   maxLength={400}
                                                />
                                             </Form.Item>
                                             {allTags?.[
                                                form.getFieldValue('message')[key]
                                                   ?.opros
                                             ] &&
                                                allTags?.[
                                                   form.getFieldValue('message')[key]
                                                      ?.opros
                                                ].length > 0 && (
                                                   <>
                                                      {allTags?.[
                                                         form.getFieldValue(
                                                            'message'
                                                         )[key]?.opros
                                                      ].map((e) => (
                                                         <Tag
                                                            key={e}
                                                            color="grey"
                                                            style={{
                                                               cursor: 'pointer'
                                                            }}
                                                            onClick={() => {
                                                               const data = [
                                                                  ...form.getFieldValue(
                                                                     'message'
                                                                  )
                                                               ]; // Создаем новый объект
                                                               data[key] = {
                                                                  ...data[key],
                                                                  otvet: e
                                                               };

                                                               form.setFieldsValue({
                                                                  message: data
                                                               });

                                                               form.setFields([
                                                                  {
                                                                     name: [
                                                                        'message',
                                                                        key,
                                                                        'otvet'
                                                                     ],
                                                                     value: e,
                                                                     touched: true
                                                                  }
                                                               ]); // Отмечаем поле как измененное
                                                            }}>
                                                            {e}
                                                         </Tag>
                                                      ))}
                                                   </>
                                                )}

                                             <VerticalSpace />
                                          </Col>
                                       </Row>
                                    ))}
                                    <Form.Item>
                                       <Button
                                          type="dashed"
                                          onClick={() => add()}
                                          block
                                          icon={<PlusOutlined />}>
                                          Добавить опрос
                                       </Button>
                                    </Form.Item>
                                 </>
                              )}
                           </Form.List>
                        </>
                     )}

                     {form.getFieldValue('messageType') === 'text' && (
                        <Form.Item
                           name="message"
                           label="Заметка"
                           rules={[
                              {
                                 required: true,
                                 message: 'Поле не может быть пустым '
                              }
                           ]}>
                           <TextArea
                              rows={8}
                              placeholder="Максимум 800 символов"
                              maxLength={800}
                           />
                        </Form.Item>
                     )}

                     {/* <Form.Item
                  name="status"
                  label="Статус"
                  rules={[
                     {
                        required: true,
                        message: 'Выберите статус'
                     }
                  ]}
                  defaultValue={statusesOfNotes.active}>
                  <Select
                     style={{
                        width: '100%'
                     }}
                     defaultValue={statusesOfNotes.active}
                     placeholder="Выберите статус"
                     rules={[
                        {
                           required: true,
                           message: 'Поле не может быть пустым '
                        }
                     ]}
                     options={Object.values(statusesOfNotes)}
                  />
               </Form.Item> */}
                  </Col>
               </Row>
               <Row gutter={[16, 24]}>
                  {errorMessage && (
                     <Col
                        className="gutter-row"
                        span={24}
                        className="stats-card-count-col">
                        <Alert
                           message={errorMessage.replace('Error: ', '')}
                           type="error"
                           showIcon
                        />
                     </Col>
                  )}

                  <Col
                     className="gutter-row"
                     span={24}
                     className="stats-card-count-col">
                     <Form.Item>
                        <Button type="primary" htmlType="submit" loading={isLoading}>
                           {isEditForm ? 'Сохранить' : 'Создать'}
                        </Button>
                     </Form.Item>
                  </Col>
               </Row>{' '}
            </>
         )}
      </Form>
   );
};

export default NoteForm;

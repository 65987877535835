import React from 'react';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

const VerificationIcon = ({ verified }) => {
   return (
      <>
         {verified ? (
            <Tooltip placement="right" title={'Верифицирован'}>
               <CheckCircleOutlined
                  style={{
                     fontSize: '18px',
                     color: 'green'
                  }}
               />
            </Tooltip>
         ) : (
            <Tooltip placement="right" title={'Не верифицирован'}>
               <CloseCircleOutlined
                  style={{
                     fontSize: '18px',
                     color: 'red'
                  }}
               />
            </Tooltip>
         )}
      </>
   );
};

export default VerificationIcon;

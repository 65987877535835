import React, { useState, useEffect } from 'react';
import NoteForm from './NoteForm';
import { CreateNote } from '../model/CreateNote';
import { UpdateNote } from '../model/UpdateNote';
import { GetNote } from '../model/GetNote';
import _ from 'lodash';
import { message } from 'antd';

const CreateOrEditNote = ({
   id = null,
   callbackOnSuccess = () => {},
   entityId,
   entityType
}) => {
   const isEditForm = !!id;
   const [initialValues, setIntialValues] = useState({});
   const [loadForm, setLoadForm] = useState(false);
   const [errorMessage, setErrorMessage] = useState('');

   useEffect(() => {
      if (!isEditForm) {
         return;
      }
      fetchData();

      return () => {
         setErrorMessage('');
         setLoadForm(false);
      };
   }, [id]);

   const fetchData = () => {
      setLoadForm(false);
      GetNote(id).then((res) => {
         setIntialValues({ ...initialValues, ...res });
         setLoadForm(true);
      });
   };

   const onSuccess = async (values, setLoading) => {
      setLoading(true);
      values = {
         ...values,
         entityId,
         entityType
      };

      if (values.messageType === 'opros') {
         values.cause = 'Опрос';
      }

      if (isEditForm) {
         const noteId = initialValues.id;

         const mergedObj = { ...initialValues, ...values };
         const updatedFields = _.pickBy(
            mergedObj,
            (v, k) => !_.isEqual(initialValues[k], v)
         );

         await UpdateNote(updatedFields, noteId)
            .then(() => {
               callbackOnSuccess();
               message.success('Заметка изменена');
            })
            .catch((e) => {
               fetchData();
               const errMessage = e?.message || 'Возникла ошибка при сохранении';
               setErrorMessage(errMessage);
               message.error(errMessage);
            })
            .finally(() => setLoading(false));

         return;
      }

      if (!values.messageType) {
         values.messageType = 'text';
      }

      await CreateNote(values)
         .then(() => {
            callbackOnSuccess();
            message.success('Заметка создана');
         })
         .catch((e) => {
            const errMessage = e?.message || 'Заметка не создана, возникла ошибка';
            setErrorMessage(errMessage);
            message.error(errMessage);
         })
         .finally(() => setLoading(false));
   };

   if (!loadForm && isEditForm) {
      // Чтобы initialValues прогрузились при первом рендере, если это Редактируемая форма
      return <></>;
   }

   return (
      <NoteForm
         errorMessage={errorMessage}
         initialValues={initialValues}
         isEditForm={isEditForm}
         onSuccess={onSuccess}
         entityType={entityType}
      />
   );
};

export default CreateOrEditNote;

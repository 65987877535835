import { Button, Modal, Space, Spin, Alert, Empty } from 'antd';
import React, { useState, useEffect } from 'react';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import { getCenter } from 'geolib';
import _ from 'lodash';
import { EnvironmentOutlined } from '@ant-design/icons';
import { GetDriverCoordinates } from '../model/GetDriverCoordinates';
import { getTimeAgo } from '@shared/utils/timeAgo';
import { StatusTag } from '@shared/ui';
import dayjs from 'dayjs';
import { DownloadOutlined } from '@ant-design/icons';

import { useNavigate } from 'react-router-dom';

const LIMIT = 8;

const isArrayEqual = function (x, y) {
   return _(x).xorWith(y, _.isEqual).isEmpty();
};

const MAX_HEIGHT_PX = 600;

const MapOfCoordinatesOfDrivers = () => {
   const [centerOfMap, setCenterOfMap] = useState({
      center: ['55.7539', '37.6208'],
      zoom: 13
   });
   const [listCoordinates, setListCoordinates] = useState([]);
   const [loading, setLoading] = useState(false);
   const [clickedDriverId, setClickedDriverId] = useState(null);
   const [currentPage, setCurrentPage] = useState(1);
   const [count, setCount] = useState(1);
   const navigate = useNavigate();

   useEffect(() => {
      fetchData();
   }, []);

   useEffect(() => {
      fetchData(currentPage);
   }, [currentPage]);

   const fetchData = (currentPage) => {
      setLoading(true);

      GetDriverCoordinates({
         page: currentPage,
         limit: LIMIT
      })
         .then((res) => {
            let data = [];
            if (currentPage === 1) {
               data = [...res.data];
            } else {
               data = [...listCoordinates, ...res.data];
            }

            setLoading(false);
            setCurrentPage(currentPage);
            setCount(res.count);
            loadCenter(data);
         })
         .catch(() => {
            setLoading(false);
         });
   };

   const loadCenter = (ls) => {
      const dot = ls.map((e) => ({
         ...e,
         latitude: e.geoLat,
         longitude: e.geoLon
      }));

      if (isArrayEqual(dot, listCoordinates)) {
         return;
      }

      const centerCoords = getCenter(dot);
      setListCoordinates(dot);
      setCenterOfMap((prev) => ({
         ...prev,
         center: [centerCoords.latitude, centerCoords.longitude]
      }));
   };

   const mapRef = React.createRef();

   if (loading) {
      return (
         <Space
            style={{
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
               height: '100px',
               width: '100%'
            }}>
            <Spin />
         </Space>
      );
   }

   const setCDriverId = (id) => {
      setCenterOfMap((prev) => ({
         ...prev,
         zoom: 16,
         center: [
            listCoordinates.find((l) => l.driverId === id)?.geoLat,
            listCoordinates.find((l) => l.driverId === id)?.geoLon
         ]
      }));
      setClickedDriverId(id);
   };

   const isDisabled = count - currentPage * LIMIT <= 0;

   return (
      <>
         <div style={{ display: 'flex' }}>
            <YMaps>
               <div
                  ref={mapRef}
                  style={{
                     border: '1px #8080804a solid'
                  }}>
                  <Map
                     height={`${MAX_HEIGHT_PX}px`}
                     width={'800px'}
                     state={centerOfMap}
                     defaultState={{ zoom: 2 }}>
                     {listCoordinates.map((l) => (
                        <Placemark
                           onClick={() => setCDriverId(l?.driverId)}
                           key={`placemark-${l.driverId}`}
                           properties={{
                              iconContent: `<div class="placemark-title status-${
                                 l?.driver?.status
                              }">Водитель №${l?.driverId} (${getTimeAgo(
                                 dayjs(l?.updatedAt).unix() * 1000
                              )})</div>`
                           }}
                           defaultGeometry={[l?.geoLat, l?.geoLon]}
                        />
                     ))}
                  </Map>
               </div>{' '}
            </YMaps>
            {listCoordinates.length > 0 ? (
               <div
                  className="list-of-drivers-onmap"
                  style={{ maxHeight: `${MAX_HEIGHT_PX}px`, marginLeft: '15px' }}>
                  <>
                     {' '}
                     {listCoordinates.map((l) => (
                        <div
                           className={`list-of-drivers-onmap-info ${
                              clickedDriverId === l?.driverId
                                 ? 'list-of-drivers-onmap-info--active'
                                 : ''
                           }`}
                           key={l?.driverId}
                           onClick={() => setCDriverId(l?.driverId)}>
                           <div className="list-of-drivers-onmap-info_id">
                              <span
                                 className="green-span-url"
                                 style={{ margin: 0 }}
                                 onClick={() => navigate(`/drivers/${l?.driverId}`)}>
                                 {`Водитель №${l?.driverId}`}
                              </span>
                           </div>
                           <div className="list-of-drivers-onmap-info_name">
                              ФИО: {l?.driver?.firstName} {l?.driver?.lastName}
                           </div>
                           <div className="list-of-drivers-onmap-info_number">
                              Телефон: {l?.driver?.mobileNumber}
                           </div>
                           <div className="list-of-drivers-onmap-info_carnumber">
                              Номер машины: {l?.driver?.carNumber}
                           </div>

                           <div className="list-of-drivers-onmap-info_time">
                              Актуальность:{' '}
                              {getTimeAgo(dayjs(l?.updatedAt).unix() * 1000)}
                           </div>
                           <div className="list-of-drivers-onmap-info_status">
                              <StatusTag status={l?.driver?.status} type="driver" />{' '}
                           </div>
                        </div>
                     ))}
                  </>

                  <Space
                     direction="horizontal"
                     style={{
                        width: '100%',
                        justifyContent: 'center',
                        marginTop: '15px',
                        marginBottom: '15px'
                     }}>
                     <Button
                        type="primary"
                        loading={loading}
                        icon={<DownloadOutlined />}
                        onClick={() => setCurrentPage((e) => e + 1)}
                        disabled={isDisabled}>
                        Загрузить больше
                     </Button>
                  </Space>
               </div>
            ) : (
               <div style={{ height: '300px', width: '100%' }}>
                  <Empty
                     image={Empty.PRESENTED_IMAGE_SIMPLE}
                     description={
                        'На данный момент нет водителей с включенной геопозицией и которые были активны в приложении'
                     }
                  />
               </div>
            )}
         </div>
      </>
   );
};

const ModalButtonDriversMap = () => {
   const [isModalOpen, setIsModalOpen] = useState(false);

   const showModal = () => {
      setIsModalOpen(true);
   };

   const handleOk = () => {
      setIsModalOpen(false);
   };

   const handleCancel = () => {
      setIsModalOpen(false);
   };

   return (
      <>
         <Button onClick={showModal} icon={<EnvironmentOutlined />}>
            Карта водителей
         </Button>
         <Modal
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            onClose={handleCancel}
            title={`Карта водителей`}
            footer={null}
            width={1200}
            destroyOnClose={true}>
            <MapOfCoordinatesOfDrivers />
         </Modal>
      </>
   );
};

export default ModalButtonDriversMap;

import React from 'react';
import TableNotes from '@features/notes/ui/TableNotes';
import { Title } from '@shared/ui';

const OprosPage = () => {
   return (
      <div>
         <Title>Опросы</Title>
         <TableNotes />
      </div>
   );
};

export default OprosPage;

import { useState, useEffect } from 'react';
import { getProgressionsOfLeads30Days } from '@shared/api/all/stats';
import { statusesOfLeads } from '@shared/const/statuses';
import { Segmented } from 'antd';
import { Line } from '@ant-design/plots';

const ServicesGraphsLeads = () => {
   const [data, setData] = useState([]);
   const [mapLabelColor, setMapLabelColor] = useState({});
   const [period, setPeriod] = useState('month');

   useEffect(() => {
      fetchData();

      const map = {};
      const mapLabelColor = Object.values(statusesOfLeads).reduce(
         (acc, { label, color }) => {
            acc[label] = color;
            return acc;
         },
         {}
      );

      setMapLabelColor(mapLabelColor);
   }, []);

   const fetchData = (type = period) => {
      getProgressionsOfLeads30Days(type).then((res) => {
         let apiArr = res?.json?.data || {};

         const result = [];

         Object.entries(apiArr).forEach(([date, records]) => {
            const counts = {};

            records.forEach(({ statusObj }) => {
               const status = statusObj.status;
               counts[status] = (counts[status] || 0) + 1;
            });

            Object.entries(counts).forEach(([status, value]) => {
               result.push({
                  date: date,
                  value,
                  status: status,
                  category: statusesOfLeads[status].label
               });
            });
         });

         setData(result);
      });
   };

   const config = {
      data,
      xField: 'date',
      yField: 'value',
      seriesField: 'category',
      point: {
         size: 5,
         shape: 'circle'
      },
      color: ({ category }) => mapLabelColor[category] || '#999',
      smooth: true
   };

   useEffect(() => {
      fetchData(period);
   }, [period]);

   return (
      <>
         {' '}
         <Segmented
            options={[
               {
                  label: 'День',
                  value: 'day'
               },
               {
                  label: 'Неделя',
                  value: 'week'
               },
               {
                  label: 'Месяц',
                  value: 'month'
               }
            ]}
            value={period}
            onChange={(value) => setPeriod(value)}
            style={{ marginBottom: 16 }}
         />
         <Line {...config} />
      </>
   );
};

export default ServicesGraphsLeads;

import { Modal, List } from 'antd';
import { useState, useEffect } from 'react';
import { GetStoriesByTag } from '../model/GetStoriesByTag';
import { DeleteStory } from '../model/DeleteStory';
import { Avatar, message } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import ModalStoryForm from './ModalStoryForm';

const { confirm } = Modal;

const ModalListOfHistoriesByTag = ({ selectedTag, closeModal }) => {
   const [isModalOpen, setIsModalOpen] = useState(false);
   const [stories, setStories] = useState([]);
   const [selectedStory, setSelectedStory] = useState(null);

   useEffect(() => {
      setIsModalOpen(!!selectedTag);
      if (selectedTag) {
         fetchData();
      }
   }, [selectedTag]);

   const fetchData = async () => {
      const data = await GetStoriesByTag(selectedTag);

      setStories(data);

      if (data?.length === 0) {
         closeModal();
      }
   };

   const handleOk = () => {
      setIsModalOpen(false);
      closeModal();
   };

   const handleCancel = () => {
      setIsModalOpen(false);
      closeModal();
   };

   const showConfirm = (id) => {
      return confirm({
         title: 'Подтвердите удаление',
         icon: <ExclamationCircleFilled />,
         maskClosable: true,
         async onOk() {
            await DeleteStory(id);
            await fetchData();
            message.success('История успешно удалена');
         },
         async onCancel() {
            await fetchData();
         },
         okText: 'Удалить',
         cancelText: 'Отмена'
      });
   };

   const closeCurrentModal = () => {
      fetchData();
      setSelectedStory(null);
   };

   const isHaveData = !!selectedTag;

   return (
      <Modal
         title={`Редактировать`}
         open={isModalOpen}
         onOk={handleOk}
         onCancel={handleCancel}
         footer={null}
         destroyOnClose={true}
         width={600}>
         <div>
            <ModalStoryForm
               selectedStory={selectedStory}
               closeModal={closeCurrentModal}
            />

            <List
               className="demo-loadmore-list"
               itemLayout="horizontal"
               dataSource={stories}
               renderItem={(item) => (
                  <List.Item
                     onClick={() => {}}
                     actions={[
                        <a
                           key="list-loadmore-edit"
                           onClick={() => setSelectedStory(item)}>
                           Редактировать
                        </a>,
                        <a
                           key="list-loadmore-delete"
                           onClick={() => showConfirm(item.id)}>
                           Удалить
                        </a>
                     ]}>
                     <List.Item.Meta
                        // avatar={<Avatar src={item.thumbnailUrl} />}
                        title={<a href="#">{item.shortTitle}</a>}
                        description={item?.seenBy?.length + ' просмотров'}
                     />
                  </List.Item>
               )}
            />
         </div>
      </Modal>
   );
};

export default ModalListOfHistoriesByTag;

export const generateSorterParam = (fieldName, orderType) => ({
   columnKey: fieldName,
   order: orderType
});

const months = [
   'Январь',
   'Февраль',
   'Март',
   'Апрель',
   'Май',
   'Июнь',
   'Июль',
   'Август',
   'Сентябрь',
   'Октябрь',
   'Ноябрь',
   'Декабрь'
];

export function getMonthName2(dateStr) {
   const [month, day] = dateStr.split('-');
   const monthIndex = parseInt(month, 10) - 1;

   return months[monthIndex] || 'Неизвестный месяц';
}

export function getMonthName(dateStr) {
   const [year, month] = dateStr.split('-');
   const monthIndex = parseInt(month, 10) - 1;

   return months[monthIndex] || 'Неизвестный месяц';
}

export const normalizeFiltersToObjWithKeyAndValue = (f) => {
   let normalizedFilterObj = {};

   for (let obj of f) {
      if (obj?.value) {
         if (normalizedFilterObj[obj?.key]) {
            normalizedFilterObj[obj?.key] = [
               ...normalizedFilterObj[obj?.key],
               obj.value
            ];
         } else {
            normalizedFilterObj[obj?.key] = [obj.value];
         }
      }
   }

   return normalizedFilterObj;
};

export const generateUUIDbyTimeNow = () => Date.now();

export const generateUUID = () => (Math.random() + 1).toString(36).substring(5);

export const addElipsisToText = (text, maxLength) => {
   if (!text) {
      return '';
   }
   return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
};

import { updateStory } from '@shared/api/all/story';

export const UpdateStory = async (fields, id) => {
   try {
      const response = await updateStory(fields, id);
      return response;
   } catch (e) {
      throw new Error(e);
   }
};

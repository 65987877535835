import { getNotesExcel } from '@shared/api/all/note';

function transformData(obj) {
   // Создаем копию metaData без ключа 'message'
   let updatedMetaData = obj.metaData.filter((meta) => meta.key !== 'message');
   let existingKeys = new Set(updatedMetaData.map((meta) => meta.key));

   // Перебираем все элементы data
   obj.data.forEach((item) => {
      if (item.message && Array.isArray(item.message)) {
         item.message.forEach((msg) => {
            if (msg.opros && msg.otvet !== undefined) {
               let key = `message_${msg.opros.replace(/\s+/g, '_')}`;
               if (!existingKeys.has(key)) {
                  updatedMetaData.push({ label: msg.opros, key });
                  existingKeys.add(key);
               }
               item[key] = msg.otvet; // Добавляем значение ответа в объект
            }
         });
      }
   });

   return {
      ...obj,
      metaData: updatedMetaData
   };
}

export const GetNoteListExcel = async (params) => {
   try {
      const response = await getNotesExcel({
         ...params,
         messageType: 'opros',
         status: 'active'
      });
      if (!response.json) throw new Error();

      return transformData(response.json);
   } catch (e) {
      console.log(e);
   }
};

import React, { useState, useEffect } from 'react';
import {
   Space,
   Table,
   Tag,
   Tooltip,
   Row,
   Col,
   Card,
   Typography,
   Badge,
   Modal,
   message
} from 'antd';
import { GetStoryList } from '../model/GetStoryList';
import { Button, VerticalSpace } from '@shared/ui';
import { Divider } from 'antd';
import { DeleteStory } from '../model/DeleteStory';
import TotalCountTableText from '@widgets/Table/TotalCountTableText';
import Pagination, { initialPaginationSettings } from '@widgets/Pagination';

import { ExclamationCircleFilled } from '@ant-design/icons';
import ModalListOfHistoriesByTag from './ModalListOfHistoriesByTag';
import ModalButtonStoryCreate from './ModalButtonStoryCreate';
const { confirm } = Modal;

import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

const Stories = ({ entityType }) => {
   const [isLoading, setIsLoading] = useState(false);
   const [data, setData] = useState([]);
   const [selectedTag, setSelectedTag] = useState(null);

   const [paramsTable, setParamsTable] = useState({
      pagination: { ...initialPaginationSettings(), pageSize: 6 },
      filters: {},
      sorter: {
         sort: 'priority',
         order: 'asc'
      }
   });

   const { pagination, filters } = paramsTable;

   useEffect(() => {
      fetchData();
   }, []);

   const fetchData = (params = paramsTable) => {
      setIsLoading(true);
      GetStoryList({
         page: params.pagination.current,
         limit: params.pagination.pageSize,
         entityType,
         ...params.sorter,
         ...params.filters
      }).then((res) => {
         setParamsTable({
            ...params,
            pagination: { ...params.pagination, total: res.count }
         });

         setData(res.data);
         setIsLoading(false);
      });
   };

   const onChangePagination = (current, pageSize) => {
      const newParams = {
         ...paramsTable,
         pagination: { ...paramsTable.pagination, current }
      };
      fetchData(newParams);
   };

   const closeModal = () => {
      setSelectedTag(null);
      fetchData();
   };

   const showConfirm = (id) => {
      return confirm({
         title: 'Удалиться только первая история в этой категории',
         icon: <ExclamationCircleFilled />,
         maskClosable: true,
         async onOk() {
            await DeleteStory(id);
            await fetchData();
            message.success('История успешно удалена');
         },
         async onCancel() {
            await fetchData();
         },
         okText: 'Удалить',
         cancelText: 'Отмена'
      });
   };

   return (
      <>
         <ModalListOfHistoriesByTag
            selectedTag={selectedTag}
            closeModal={closeModal}
         />

         <div className="table-right-block-top">
            <div className="table-right-block-top_item">
               <Space>
                  {entityType === 'seller' && (
                     <ModalButtonStoryCreate closeModal={closeModal} />
                  )}

                  <TotalCountTableText total={pagination.total} />
               </Space>
            </div>
         </div>
         <VerticalSpace />
         <Row gutter={16}>
            {data.map((story) => (
               <Col
                  span={4}
                  key={story.id}
                  style={{ maxWidth: '100%', width: '100%' }}>
                  <Card
                     actions={[
                        <EditOutlined
                           key="edit"
                           style={{ color: 'green' }}
                           onClick={() => setSelectedTag(story.tag)}
                        />,
                        <a
                           key="list-loadmore-delete"
                           onClick={() => showConfirm(story.id)}>
                           Удалить
                        </a>
                     ]}>
                     {/* <Badge
                        count={story.status === 'active' ? 'Активный' : 'Неактивный'}
                        color={story.status === 'active' ? 'green' : 'red'}></Badge> */}

                     {story.shortTitle}
                     {/* <br />
                     {story.tag} */}
                  </Card>
               </Col>
            ))}
         </Row>
         <VerticalSpace />
         <Space style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {!!pagination.total && (
               <Pagination
                  pagination={pagination}
                  onChangePagination={onChangePagination}
                  isLoading={isLoading}
               />
            )}
         </Space>
      </>
   );
};

export default Stories;

import { get, generateQueryParams } from '../fetch.js';

export const getCountOfService = (service, params) =>
   get(generateQueryParams(`/stats/count/${service}`, params));

export const getProgressionsOfEntitiesLast30Days = () => get(`/stats/progression`);

export const getProgressionsOfBuyedLeadsMonth = (type) =>
   get(`/stats/bydldsmonth?type=${type}`);

export const getProgressionsOfLeads30Days = (type) =>
   get(`/stats/leads?type=${type}`);
export const getProgressionsOfLeadsFinishedConfirms30Days = (type) =>
   get(`/stats/leadconfirms?type=${type}`);

export const getAllRoutesCommission = () => get(`/stats/routes/commissions`);
export const getAllLeadsCapacities = () => get(`/stats/leads/capacities`);

export const getFreeCars = () => get(`/stats/cars/free`);
export const getWorkCars = () => get(`/stats/cars/work`);

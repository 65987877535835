import { get, post, put, patch, generateQueryParams, _delete } from '../fetch.js';

export const getLeadById = (id) => get(`/lead/${id}`);

export const getLeadConfirmById = (id) => get(`/leadconfirm/${id}`);
export const updateLeadConfirmById = (id, fields) =>
   put(
      `/leadconfirm/${id}`,
      {
         ...fields
      },
      true
   );

export const getLeadEarningById = (id) => get(`/lead/${id}/earning`);

export const getLeads = (params) => get(generateQueryParams(`/leads`, params));
export const getLeadsExcel = (params) =>
   get(generateQueryParams(`/leadexcel`, params));

export const deleteLeadById = (id) => _delete(`/lead/${id}`, {}, true);

export const updateStatusOfField = (fields, id) =>
   patch(
      `/lead/${id}`,
      {
         ...fields
      },
      true
   );

export const createLead = (fields) =>
   post(
      '/lead',
      {
         ...fields
      },
      true
   );

export const createPublicLead = (fields) =>
   post(
      '/public/lead',
      {
         ...fields
      },
      true
   );

export const updateLead = (fields, id) =>
   put(
      `/lead/${id}`,
      {
         ...fields
      },
      true
   );

export const updateLeadPublicImage = (fields, id) =>
   put(
      `/public/lead/${id}/image`,
      {
         ...fields
      },
      true
   );

/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Select, VerticalSpace } from '@shared/ui';
import { statusesOfAcceptances } from '@shared/const/statuses';
import SelectAddress from '@widgets/FormItems/SelectAddress';
import { Col, Row, Checkbox } from 'antd';
import { GetCategoriesList } from '@features/manage-materialcategories/model/services/GetCategoriesList';
import {
   PlusOutlined,
   MinusCircleOutlined,
   DeleteOutlined
} from '@ant-design/icons';
import { message, Alert } from 'antd';
import { unitSettings } from '@shared/const/units';
import SelectDriver from '@widgets/FormItems/SelectDriver';
import InputUniqueNumberOfEntity from '@widgets/FormItems/InputUniqueNumberOfEntity';
import TimePicker from 'antd/lib/time-picker';
import dayjs from 'dayjs';
import { normalizeLeadTimes } from '@shared/lib/leadTimes';

const { TextArea } = Input;

const AcceptanceForm = (props) => {
   const [isLoading, setIsLoading] = useState(false);
   const { initialValues, onSuccess, isEditForm } = props;
   const [isDisabledButton, setIsDisabledButton] = useState(true);

   const [categories, setCategories] = useState([]);
   const [form] = Form.useForm();

   const onFinish = (values) => {
      if (values?.workScheldue?.length > 0) {
         values.workScheldue = values.workScheldue.map((e) => {
            return {
               dayOfWeek: e.dayOfWeek,
               fromTimestamp: e.timeArr[0].format('HH:mm'),
               toTimestamp: e.timeArr[1].format('HH:mm')
            };
         });
      }

      if (values?.entityCategories?.length > 0) {
         values.entityCategories = values.entityCategories.map((e) => ({
            materialCategoryId: e.materialCategoryId,
            price: e.price
         }));

         let categoriesIds = values.entityCategories.map(
            (e) => e.materialCategoryId
         );
         if (categoriesIds.length !== [...new Set(categoriesIds)].length) {
            message.error('Вы выбрали одинаковые категории');
            return;
         }
      }

      onSuccess(values, setIsLoading).then(() => {
         if (isEditForm) {
            return;
         }

         // form.resetFields();
      });
   };

   useEffect(() => {
      GetCategoriesList({
         page: 1,
         limit: 1000,
         sort: 'id',
         order: 'desc',
         status: 'active'
      }).then((res) => {
         setCategories(res.data);
      });
   }, []);

   const onFinishFailed = (errorInfo) => {
      setIsLoading(false);
      console.log('Failed:', errorInfo);
   };

   const currentStatus = initialValues?.status || 'active';

   const isDisabledInput = (inputName) => {
      const list = {
         firstName: ['pending', 'active'],
         lastName: ['pending', 'active'],
         title: ['pending', 'active'],
         driverId: ['pending', 'active'],
         mobileNumber: ['pending', 'active'],
         address: ['pending', 'active'],
         status: ['active', 'blocked', 'pending'],
         entityCategories: ['pending', 'active'],
         materialCategoryId: ['pending', 'active'],
         price: ['pending', 'active'],
         comment: ['active', 'pending', 'inwork', 'finished'],
         timeArr: ['active', 'pending', 'inwork', 'finished'],
         workScheldue: ['active', 'pending', 'inwork', 'finished'],
         dayOfWeek: ['active', 'pending', 'inwork', 'finished']
      };

      return !list[inputName].includes(currentStatus);
   };

   const statusesCanBeDoOnCurrent = {
      pending: ['pending', 'active'],
      active: ['active', 'blocked'],
      blocked: ['blocked', 'active']
   };

   const isAcceptanceBlocked = currentStatus === 'blocked';
   const isAcceptancePending = currentStatus === 'pending';

   const actualStatuses = initialValues?.status
      ? statusesCanBeDoOnCurrent[currentStatus].map((e) => statusesOfAcceptances[e])
      : [statusesOfAcceptances.pending];

   const suffix = (obj) => {
      if (!obj?.materialCategoryId) {
         return '';
      }

      const unit = categories.find((u) => u.id === obj?.materialCategoryId)?.unit;
      const suffix =
         `руб/` + unitSettings.find((e) => e?.value === unit)?.shortLabel;
      return suffix;
   };

   initialValues.address = initialValues?.address?.address;

   const modifiedInitialValues = {
      ...initialValues,
      workScheldue: initialValues?.workScheldue
         ? initialValues?.workScheldue.map((e) => ({
              dayOfWeek: e.dayOfWeek,
              timeArr: [
                 dayjs(e.fromTimestamp, 'HH:mm'),
                 dayjs(e.toTimestamp, 'HH:mm')
              ]
           }))
         : []
   };

   return (
      <Form
         name="basic"
         style={{
            minWidth: 320
         }}
         form={form}
         initialValues={modifiedInitialValues}
         onFinish={onFinish}
         onFinishFailed={onFinishFailed}
         onValuesChange={() => setIsDisabledButton(false)}
         hideRequiredMark
         layout="vertical">
         <Row gutter={16}>
            <Col span={12}>
               <Form.Item
                  label="Название"
                  name="title"
                  // rules={!isDisabledInput("title") && russianOneWordRequired()}
                  rules={
                     !isDisabledInput('title') && [
                        { required: true, message: 'Укажите название' }
                     ]
                  }>
                  <Input
                     placeholder="Название приемки"
                     disabled={isDisabledInput('title')}
                  />
               </Form.Item>
            </Col>

            <Col span={12}>
               <InputUniqueNumberOfEntity
                  disabled={isDisabledInput('mobileNumber')}
                  entityType="acceptance"
               />
            </Col>
         </Row>
         <Row gutter={24}>
            <Col span={8}>
               <Form.Item
                  label="Имя"
                  name="firstName"
                  // rules={!isDisabledInput("firstName") && russianOneWordRequired()}
               >
                  <Input
                     placeholder="Введите имя"
                     disabled={isDisabledInput('firstName')}
                  />
               </Form.Item>
            </Col>
            <Col span={8}>
               <Form.Item
                  label="Фамилия"
                  name="lastName"
                  // rules={!isDisabledInput("lastName") && russianOneWordRequired()}
               >
                  <Input
                     placeholder="Введите фамилию"
                     disabled={isDisabledInput('lastName')}
                  />
               </Form.Item>
            </Col>
            <Col span={8}>
               <Form.Item
                  name="status"
                  label="Статус"
                  rules={
                     !isDisabledInput('status') && [
                        {
                           required: true,
                           message: 'Выберите статус'
                        }
                     ]
                  }
                  defaultValue={statusesOfAcceptances.pending}>
                  <Select
                     style={{
                        width: '100%'
                     }}
                     placeholder="Выберите статус"
                     disabled={isDisabledInput('status')}
                     rules={[
                        {
                           required: true,
                           message: 'Поле не может быть пустым '
                        }
                     ]}
                     options={actualStatuses}
                  />
               </Form.Item>
            </Col>
         </Row>
         <Row gutter={16}>
            <Col span={12}>
               <Form.Item
                  label="Адрес"
                  name={'address'}
                  rules={
                     !isDisabledInput('address') && [
                        { required: true, message: 'Укажите адрес' }
                     ]
                  }>
                  <SelectAddress disabled={isDisabledInput('address')} allowClear />
               </Form.Item>
            </Col>
            <Col span={12}>
               <Form.Item name="driverId" label="Водитель">
                  <SelectDriver
                     disabled={isDisabledInput('driverId')}
                     driver={initialValues?.driver || {}}
                     driverId={
                        form.getFieldValue('driverId') || initialValues?.driverId
                     }
                     setField={(value) => {
                        form.setFieldValue('driverId', value);
                        setIsDisabledButton(false);
                     }}
                  />
               </Form.Item>
            </Col>
         </Row>
         <Row gutter={16}>
            <Col span={24}>
               <Form.Item name="entityCategories" label="Категории сырья и цены">
                  <Form.List name="entityCategories">
                     {(fields, { add, remove }) => (
                        <>
                           {fields.map(({ key, name, ...restField }) => (
                              <Row gutter={24} key={`entityCategories${key}`}>
                                 <Col span={14}>
                                    <Form.Item
                                       {...restField}
                                       name={[name, 'materialCategoryId']}
                                       rules={
                                          !isDisabledInput('materialCategoryId') && [
                                             {
                                                required: true,
                                                message: 'Укажите категорию'
                                             }
                                          ]
                                       }>
                                       <Select
                                          {...restField}
                                          allowClear
                                          style={{
                                             width: '100%'
                                          }}
                                          placeholder="Выберите категорию"
                                          fieldNames={{
                                             label: 'name',
                                             value: 'id'
                                          }}
                                          disabled={isDisabledInput(
                                             'materialCategoryId'
                                          )}
                                          options={categories}
                                       />
                                    </Form.Item>
                                 </Col>

                                 <Col span={8}>
                                    <Form.Item {...restField} name={[name, 'price']}>
                                       <Input
                                          disabled={isDisabledInput('price')}
                                          placeholder="Цена"
                                          type="number"
                                          addonAfter={
                                             <Form.Item noStyle>
                                                {suffix(
                                                   form.getFieldValue(
                                                      'entityCategories'
                                                   )[name]
                                                )}
                                             </Form.Item>
                                          }
                                       />
                                    </Form.Item>
                                 </Col>
                                 <Col span={2}>
                                    {!isDisabledInput('price') && (
                                       <MinusCircleOutlined
                                          onClick={() => remove(name)}
                                       />
                                    )}
                                 </Col>
                              </Row>
                           ))}
                           <Form.Item>
                              <Button
                                 type="dashed"
                                 onClick={() => add()}
                                 block
                                 icon={<PlusOutlined />}
                                 disabled={isDisabledInput('price')}>
                                 Добавить
                              </Button>
                           </Form.Item>
                        </>
                     )}
                  </Form.List>
               </Form.Item>
            </Col>
         </Row>
         <Row gutter={[16, 24]}>
            <Col span={24}>
               <Form.Item name="comment" label="Комментарий">
                  <TextArea
                     disabled={isDisabledInput('comment')}
                     rows={3}
                     placeholder="Максимум 500 символов"
                     maxLength={500}
                  />
               </Form.Item>
            </Col>
         </Row>
         <Form.Item
            className="hide-label"
            layout="horizontal"
            label="Автомобильные весы"
            name="isHaveCarScales"
            valuePropName="checked">
            <Checkbox>Автомобильные весы</Checkbox>
         </Form.Item>{' '}
         <Form.Item
            className="hide-label"
            layout="horizontal"
            label="Вьезд только на машине"
            name="accessByCarOnly"
            valuePropName="checked">
            <Checkbox>Вьезд только на машине</Checkbox>
         </Form.Item>{' '}
         <Form.Item
            layout="horizontal"
            label="Самопривоз минимальный вес от"
            name="selfImportMinimumWeight">
            <Input
               placeholder="Укажите вес"
               type="number"
               style={{ maxWidth: '240px' }}
               addonAfter="кг (литров)"
            />
         </Form.Item>{' '}
         <VerticalSpace />
         <Row gutter={24}>
            <Col span={14}>
               <Form.Item label="График работы">
                  <Form.List name="workScheldue">
                     {(fields, { add, remove }) => (
                        <>
                           {fields.map(({ key, name, ...restField }) => (
                              <Row gutter={24} key={`workScheldue${key}`}>
                                 <Col span={10}>
                                    <Form.Item
                                       {...restField}
                                       name={[name, 'dayOfWeek']}
                                       rules={
                                          !isDisabledInput('dayOfWeek') && [
                                             {
                                                required: true,
                                                message: 'Укажите категорию'
                                             },
                                             {
                                                validator: (rule, value) => {
                                                   if (value) {
                                                      const isDuplicate =
                                                         form
                                                            .getFieldValue(
                                                               'workScheldue'
                                                            )
                                                            .filter(
                                                               (e) =>
                                                                  e.dayOfWeek ===
                                                                  value
                                                            ).length > 1;
                                                      if (isDuplicate) {
                                                         return Promise.reject(
                                                            'Этот день недели уже выбран'
                                                         );
                                                      }
                                                   }
                                                   return Promise.resolve();
                                                }
                                             }
                                          ]
                                       }>
                                       <Select
                                          {...restField}
                                          allowClear
                                          style={{
                                             width: '100%'
                                          }}
                                          placeholder="Выберите день недели"
                                          fieldNames={{
                                             label: 'name',
                                             value: 'id'
                                          }}
                                          disabled={isDisabledInput('dayOfWeek')}
                                          options={[
                                             { id: 1, name: 'Понедельник' },
                                             { id: 2, name: 'Вторник' },
                                             { id: 3, name: 'Среда' },
                                             { id: 4, name: 'Четверг' },
                                             { id: 5, name: 'Пятница' },
                                             { id: 6, name: 'Суббота' },
                                             { id: 7, name: 'Воскресенье' }
                                          ]}
                                       />
                                    </Form.Item>
                                 </Col>
                                 <Col span={12}>
                                    <Form.Item
                                       {...restField}
                                       name={[name, 'timeArr']}
                                       rules={[
                                          {
                                             required: true,
                                             message: 'Укажите время'
                                          }
                                       ]}>
                                       <TimePicker.RangePicker
                                          disabled={isDisabledInput('dayOfWeek')}
                                          format="HH:mm"
                                          placeholder={['Время с', 'Время по']}
                                          minuteStep="5"
                                          showNow
                                          style={{ width: '100%' }}
                                       />
                                    </Form.Item>
                                 </Col>
                                 <Col span={2}>
                                    <Button
                                       style={{
                                          float: 'right',
                                          marginBottom: '20px'
                                       }}
                                       danger
                                       type="primary"
                                       icon={<DeleteOutlined />}
                                       onClick={() => remove(name)}></Button>
                                 </Col>
                              </Row>
                           ))}{' '}
                           <Form.Item>
                              <Button
                                 type="dashed"
                                 onClick={() => add()}
                                 block
                                 style={{ maxWidth: '220px' }}
                                 icon={<PlusOutlined />}>
                                 Добавить день недели
                              </Button>
                           </Form.Item>
                        </>
                     )}
                  </Form.List>
               </Form.Item>
            </Col>
         </Row>
         <Row gutter={[16, 24]}>
            {isAcceptanceBlocked && (
               <Col
                  className="gutter-row"
                  span={24}
                  className="stats-card-count-col">
                  <Alert
                     message="В статусе заблокирован нельзя редактировать все поля"
                     type="warning"
                     showIcon
                  />
               </Col>
            )}
            {/* {isAcceptancePending && (
               <Col
                  className="gutter-row"
                  span={24}
                  className="stats-card-count-col">
                  <Alert
                     message="Активация статуса проходит строго в телеграм боте"
                     type="info"
                     showIcon
                  />
               </Col>
            )} */}

            <Col className="gutter-row" span={24} className="stats-card-count-col">
               <Form.Item>
                  <Button
                     type="primary"
                     htmlType="submit"
                     loading={isLoading}
                     disabled={isDisabledButton}>
                     {isEditForm ? 'Сохранить' : 'Создать'}
                  </Button>
               </Form.Item>
            </Col>
         </Row>
      </Form>
   );
};

export default AcceptanceForm;

/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Alert, Button, Title, Select, VerticalSpace } from '@shared/ui';
import Stories from '@features/list-story/ui/Stories';
import { useNavigate } from 'react-router-dom';

const MobileAppsStoriesPage = () => {
   return (
      <div>
         <Title>Истории продавцов</Title>

         <Stories entityType="seller" />

         <Title>Истории водителей</Title>

         <Stories entityType="driver" />
      </div>
   );
};

export default MobileAppsStoriesPage;

import { deleteStoryById } from '@shared/api/all/story';

export const DeleteStory = async (id) => {
   try {
      const response = await deleteStoryById(id);
      return response;
   } catch (e) {
      throw new Error(e);
   }
};

import { get, generateQueryParams, _delete, post, put } from '../fetch.js';

export const getAddressInfo = (params) =>
   get(generateQueryParams(`/address/seller`, params));

export const getAddressSuggestions = (params) =>
   get(generateQueryParams(`/address/suggestions`, params));

export const getCitiesSuggestions = (params) =>
   get(generateQueryParams(`/cities/suggestions`, params));

export const deleteAddressById = (id) => _delete(`/address/${id}`, {}, true);

export const updateAddress = (fields, id) =>
   put(
      `/address/${id}`,
      {
         ...fields
      },
      true
   );

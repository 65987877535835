import { useState, useEffect } from 'react';
import { getProgressionsOfBuyedLeadsMonth } from '@shared/api/all/stats';
import { getMonthName, getMonthName2 } from '@shared/utils/default';
import { Segmented } from 'antd';

import { Line } from '@ant-design/plots';

const ServicesGraphsBuyedLeadsCount = () => {
   const [data, setData] = useState([]);
   const [period, setPeriod] = useState('month');

   const fetchData = (type) => {
      getProgressionsOfBuyedLeadsMonth(type).then((res) => {
         let apiArr = res?.json?.data || [];

         apiArr = Object.keys(apiArr).map((key) => {
            return {
               month: period === 'month' ? getMonthName2(key).toLowerCase() : key,
               totalChange: apiArr[key],
               category: 'Куплено заявок'
            };
         });
         setData(apiArr);
      });
   };

   useEffect(() => {
      fetchData(period);
   }, [period]);

   const config = {
      data,
      xField: 'month',
      yField: 'totalChange',
      seriesField: 'category',
      point: {
         size: 5,
         shape: 'circle'
      },
      color: 'green',
      smooth: true
   };

   return (
      <>
         <Segmented
            options={[
               {
                  label: 'День',
                  value: 'day'
               },
               {
                  label: 'Неделя',
                  value: 'week'
               },
               {
                  label: 'Месяц',
                  value: 'month'
               }
            ]}
            value={period}
            onChange={(value) => setPeriod(value)}
            style={{ marginBottom: 16 }}
         />

         <Line {...config} />
      </>
   );
};

export default ServicesGraphsBuyedLeadsCount;

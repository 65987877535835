import { Button, Modal } from 'antd';
import { useState } from 'react';
import CreateOrEditStory from './CreateOrEditStory';

import { CalendarOutlined } from '@ant-design/icons';

const ModalButtonStoryCreate = ({ closeModal, buttonText = 'Создать' }) => {
   const [isModalOpen, setIsModalOpen] = useState(false);

   const showModal = () => {
      setIsModalOpen(true);
   };

   const handleOk = () => {
      setIsModalOpen(false);
      closeModal();
   };

   const handleCancel = () => {
      setIsModalOpen(false);
      closeModal();
   };

   return (
      <>
         <Button type="primary" style={{ marginRight: '15px' }} onClick={showModal}>
            Создать историю
         </Button>
         <Modal
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            onClose={handleCancel}
            title={`Создать историю`}
            footer={null}
            width={600}
            destroyOnClose={true}>
            <CreateOrEditStory id={null} callbackOnSuccess={handleCancel} />
         </Modal>
      </>
   );
};

export default ModalButtonStoryCreate;

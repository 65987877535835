/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Select } from '@shared/ui';
import { Col, Row, Alert, DatePicker, Radio } from 'antd';
import dayjs from 'dayjs';

const { TextArea } = Input;

const StoryForm = (props) => {
   const [isLoading, setIsLoading] = useState(false);
   const [isDisabledButton, setIsDisabledButton] = useState(true);
   const { initialValues, onSuccess, isEditForm, errorMessage } = props;
   const [form] = Form.useForm();

   const onFinish = (values) => {
      onSuccess(values, setIsLoading).then(() => {
         if (isEditForm) {
            return;
         }
      });
   };

   const onFinishFailed = (errorInfo) => {
      setIsLoading(false);
      console.log('Failed:', errorInfo);
   };

   const modifiedInitialValues = {
      ...initialValues
   };

   return (
      <Form
         name="basic"
         style={{
            minWidth: 320
         }}
         form={form}
         onFinish={onFinish}
         initialValues={modifiedInitialValues}
         onFinishFailed={onFinishFailed}
         onValuesChange={() => setIsDisabledButton(false)}
         hideRequiredMark
         layout="vertical">
         {() => (
            <>
               {' '}
               <Row gutter={24}>
                  <Col span={24}>
                     <Form.Item
                        name="shortTitle"
                        label="Краткое название"
                        rules={[
                           {
                              required: true,
                              message: 'Поле пропущено!'
                           },
                           {
                              max: 40,
                              message: 'Максимум 40 символов'
                           }
                        ]}>
                        <Input
                           style={{
                              width: '100%'
                           }}
                        />
                     </Form.Item>
                  </Col>

                  <Col span={24}>
                     <Form.Item
                        name="thumbnailUrl"
                        label="Ссылка на маленькое изображение (можно пустым оставить)">
                        <Input
                           style={{
                              width: '100%'
                           }}
                        />
                     </Form.Item>
                  </Col>

                  <Col span={24}>
                     <Form.Item
                        name="tag"
                        label="Тег (для группировки историй) либо оставьте пустым">
                        <Input
                           style={{
                              width: '100%'
                           }}
                        />
                     </Form.Item>
                  </Col>

                  <Col span={12}>
                     <Form.Item
                        name="contentType"
                        label="Выберите тип"
                        rules={[
                           {
                              required: true,
                              message: 'Выберите приложение'
                           }
                        ]}>
                        <Radio.Group buttonStyle="solid">
                           <Radio.Button value="text">Текст</Radio.Button>
                           <Radio.Button value="image">Изображение</Radio.Button>
                        </Radio.Group>
                     </Form.Item>
                  </Col>
                  <Col span={12}>
                     <Form.Item
                        name="entityType"
                        label="Выберите приложение"
                        rules={[
                           {
                              required: true,
                              message: 'Выберите приложение'
                           }
                        ]}>
                        <Radio.Group buttonStyle="solid">
                           <Radio.Button value="seller">Продавцы</Radio.Button>
                           <Radio.Button value="driver">Водители</Radio.Button>
                        </Radio.Group>
                     </Form.Item>
                  </Col>

                  <Col span={24}>
                     <Form.Item
                        name="content"
                        label="Содержимое"
                        rules={[
                           {
                              required: true,
                              message: 'Содержимое не может быть пустым'
                           }
                        ]}>
                        <Input
                           style={{
                              width: '100%'
                           }}
                        />
                     </Form.Item>
                  </Col>

                  <Col span={24}>
                     <Form.Item
                        name="link"
                        label="Кнопка куда ведет (можно пустым оставить)">
                        <Input
                           style={{
                              width: '100%'
                           }}
                        />
                     </Form.Item>
                  </Col>

                  <Col span={24}>
                     <Form.Item
                        name="priority"
                        label="Приоритет"
                        rules={[
                           {
                              required: true,
                              message: 'Укажите приоритет'
                           },
                           {
                              validator: (_, value) => {
                                 if (
                                    value === undefined ||
                                    value === null ||
                                    value === ''
                                 ) {
                                    return Promise.resolve();
                                 }
                                 const numValue = Number(value);
                                 if (isNaN(numValue)) {
                                    return Promise.reject('Введите число');
                                 }
                                 if (numValue < 0) {
                                    return Promise.reject('Минимальное значение 0');
                                 }
                                 if (numValue > 100) {
                                    return Promise.reject(
                                       'Максимальное значение 100'
                                    );
                                 }
                                 return Promise.resolve();
                              }
                           }
                        ]}>
                        <Input
                           style={{
                              width: '100%'
                           }}
                           placeholder={'Введите приоритет'}
                           type="number"
                        />
                     </Form.Item>
                  </Col>
               </Row>
               <Row gutter={[16, 24]}>
                  {errorMessage && (
                     <Col
                        className="gutter-row"
                        span={24}
                        className="stats-card-count-col">
                        <Alert
                           message={errorMessage.replace('Error: ', '')}
                           type="error"
                           showIcon
                        />
                     </Col>
                  )}

                  <Col
                     className="gutter-row"
                     span={24}
                     className="stats-card-count-col">
                     <Form.Item>
                        <Button
                           type="primary"
                           htmlType="submit"
                           loading={isLoading}
                           disabled={isDisabledButton}>
                           {isEditForm ? 'Сохранить' : 'Создать'}
                        </Button>
                     </Form.Item>
                  </Col>
               </Row>
            </>
         )}
      </Form>
   );
};

export default StoryForm;

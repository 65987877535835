import React, { useState, useEffect } from 'react';
import { Title } from '@shared/ui';
/* eslint-disable react/jsx-no-duplicate-props */
import { Helmet } from 'react-helmet';
import { Button, Form, Input, Select } from '@shared/ui';
import {
   Col,
   Row,
   DatePicker,
   Alert,
   InputNumber,
   Radio,
   Switch,
   Checkbox
} from 'antd';
import { GetCategoriesList } from '@features/manage-materialcategories/model/services/GetCategoriesList';
import UploadPicturesWall from '@widgets/PicturesWall/UploadPicturesWall';
import {
   MinusCircleOutlined,
   PlusOutlined,
   CheckCircleOutlined,
   DeleteOutlined
} from '@ant-design/icons';
import { addressExtraFields } from '@shared/const/leadExtraFields';
import { Typography } from 'antd';
import SelectAddress from '@widgets/FormItems/SelectAddress';
import { TimePicker, Modal } from 'antd';
import { leadExtraFieldsToExport } from '@shared/const/leadExtraFields';
import { message, theme } from 'antd';
import { mobileNumberRequired } from '@shared/config/fieldValidatorSettings';
import { getTypeOfAddresses } from '@shared/api/all/const';
import dayjs from 'dayjs';
import { normalizeLeadTimes } from '@shared/lib/leadTimes';
import { getEntityCategoryPrice } from '@shared/api/all/category';
import _ from 'lodash';
import { UpdatePublicLeadImage } from '@features/list-lead/model/UpdatePublicLeadImage';
import { CreatePublicLead } from '@features/list-lead/model/CreatePublicLead';

const MIN_CAPACITY = 70;

const { TextArea } = Input;
const { Text } = Typography;
// eslint-disable-next-line arrow-body-style
const disabledDate = (current) => {
   // Can not select days before today and today s
   return current && current < dayjs().startOf('day');
};
const disabledDateTime = () => ({
   disabledHours: () => [0, 1, 2, 3, 4, 5]
});

const CreateLeadPublic = () => {
   const [isLoading, setIsLoading] = useState(false);
   const [errorMessage, setErrorMessage] = useState('');
   const [pricedEntity, setPricedEntitys] = useState([{}]);
   const [pricesCache, setPricesCache] = useState([]);
   const [form] = Form.useForm();
   const [categories, setCategories] = useState([]);
   const [priceObj, setPriceObj] = useState({
      maxPrice: 0,
      text: '',
      textType: 'info'
   });

   const [typeOfAddresses, setTypeOfAddresses] = useState([]);
   // eslint-disable-next-line no-unused-vars

   const { token } = theme.useToken();

   const contentStyle = {
      padding: '20px',
      color: token.colorTextTertiary,
      backgroundColor: token.colorFillAlter,
      borderRadius: token.borderRadiusLG,
      border: `1px dashed ${token.colorBorder}`,
      marginTop: 16
   };

   useEffect(() => {
      fetchTypeOfAddresses();
   }, []);

   const fetchTypeOfAddresses = async () => {
      getTypeOfAddresses().then((res) => {
         setTypeOfAddresses(res?.json?.data || []);
      });
   };

   const YandexMetrika = () => {
      const allowedDomain = window.location.hostname === 'app.ecorium.tech';

      if (!allowedDomain) return null;

      console.log('Yandex Metrika loaded');

      return (
         <Helmet>
            <script>
               {`(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};m[i].l=1*new Date();for(var j=0;j<document.scripts.length;j++){if(document.scripts[j].src===r){return;}}k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})(window,document,'script','https://mc.yandex.ru/metrika/tag.js','ym');ym(89358308,'init',{clickmap:true,trackLinks:true,accurateTrackBounce:true,webvisor:true});`}
            </script>

            <noscript>
               {`<img src='https://mc.yandex.ru/watch/89358308' />`}
            </noscript>
         </Helmet>
      );
   };

   useEffect(() => {
      GetCategoriesList({
         page: 1,
         limit: 1000,
         sort: 'priority',
         order: 'asc',
         status: 'active'
      }).then((res) => {
         setCategories(res.data);
      });
   }, []);

   const loadSuccessModal = () => {
      Modal.success({
         title: 'Ваша заявка успешно размещена',
         closable: true,
         maskClosable: true,
         okText: 'Закрыть',
         okButtonProps: {
            type: 'secondary'
         },

         content: (
            <>
               Чтобы отслеживать ее статус можно отслеживать ее статус, Перейдя в
               раздел "Мои заявки" в мобильном приложение
               <br />
               <div>
                  <a
                     href="https://ecorium.ru/app-for-sellers.html"
                     target="_blank"
                     rel="noopener noreferrer"
                     style={{
                        display: 'block',
                        textAlign: 'center',
                        color: 'green',
                        fontWeight: 700,
                        fontSize: '20px',
                        marginTop: '15px'
                     }}>
                     <Button type="primary">Ссылка на приложение</Button>
                  </a>
               </div>
            </>
         )
      });
   };

   const setImagesIds = (arr) => {
      form.setFieldValue('images', arr);
   };

   const UploadImageToCreatedLead = (leadId, callBack) => {
      const handleOk = () => {
         // Отправляем обновленные идентификаторы изображений в UpdateLead
         if (!window.arr) {
            message.error('Картинки не загружены');
            return;
         }

         if (window.arr) {
            UpdatePublicLeadImage({ images: window.arr || [] }, leadId);
            window.arr = undefined;
         }

         loadSuccessModal();
      };

      const setImagesIds = (arr) => {
         window.arr = arr;
      };

      Modal.confirm({
         title: `Заявка создана успешно!`,
         okText: 'Загрузить фото',
         cancelText: 'Закрыть',
         closable: true,
         maskClosable: true,
         icon: <CheckCircleOutlined style={{ color: 'green', fontSize: '36px' }} />,
         content: (
            <div>
               Номер вашей заявки: № {leadId} <br />В ближайшее время ею займутся
               наши менеджеры <br />
               <div
                  style={{
                     color: 'blue',
                     fontWeight: 700,
                     marginTop: '10px',
                     fontSize: '16px'
                  }}>
                  Внимание! Пожалуйста, загрузите фото вашего сырья, это ускорит
                  процесс обработки
               </div>
               <br /> <br />
               <Form.Item name="images" label="">
                  <UploadPicturesWall
                     maxCount={10}
                     images={[]}
                     setImagesIds={setImagesIds}
                  />
               </Form.Item>
            </div>
         ),
         onOk: handleOk,
         afterClose: callBack,
         onCancel() {
            loadSuccessModal();
         }
      });
   };

   const createLead = (values) => {
      if (values?.entityCategories?.length > 0) {
         values.entityCategories = values.entityCategories.map((e) => ({
            materialCategoryId: e.materialCategoryId,
            price: e.price,
            capacity: e.capacity
         }));

         let categoriesIds = values.entityCategories.map(
            (e) => e.materialCategoryId
         );
         if (categoriesIds.length !== [...new Set(categoriesIds)].length) {
            message.error('Вы выбрали одинаковые категории');
            return;
         }
      }

      if (window.cantSubmitBecauseImageUploadingNow) {
         message.error('Картинки грузятся, подождите');
         return;
      }

      if (typeof values?.price === 'string') {
         if (!values.price.includes('.')) {
            values.price = values.price + '.00';
         } else {
            values.price = parseFloat(values.price).toFixed(1);
         }
      }

      if (values?.leadTimes?.length > 0) {
         const normalized = normalizeLeadTimes(values?.leadTimes, { leadTimes: [] });
         values.leadTimes = normalized;
      } else {
         message.error('Заполните даты вызова');
         return;
      }

      if (values.status) {
         values.status = {
            status: values.status || 'active',
            comment: values?.statusComment || ''
         };
      }

      values.priceType = 'total';

      setIsLoading(true);

      CreatePublicLead({ leadData: values, images: values?.images || [] })
         .then((res) => {
            UploadImageToCreatedLead(res?.id, () => {
               form.resetFields();
               setErrorMessage('');
               setPriceObj({
                  maxPrice: 0,
                  text: '',
                  textType: 'info'
               });
            });
         })
         .catch((e) => {
            const errMessage = e?.message || 'Возникла ошибка при сохранении';
            setErrorMessage(errMessage);
            message.error(errMessage);
         })
         .finally(() => setIsLoading(false));
   };

   const onFinish = (values) => {
      if (isGoodCapacity() !== null) {
         message.error('Общий вес сырья должен быть больше 70 кг');
         return;
      }

      createLead(values);
   };

   const onFinishFailed = (errorInfo) => {
      setIsLoading(false);
      console.log('Failed:', errorInfo);
   };

   const suffixSelector = <Form.Item noStyle>кг</Form.Item>;
   const suffixSelectorPrice = <Form.Item noStyle>руб</Form.Item>;

   const modifiedInitialValues = {
      price: '0,0',
      priceType: 'unit',
      statusComment: '',
      leadTimes: [],
      finishDate: null,
      distance: 0,
      entityCategories: [{}],
      isLoadingRequired: false
   };

   useEffect(() => {
      if (
         form.getFieldValue('entityCategories') &&
         form.getFieldValue('entityCategories').length > 0
      ) {
         normalizePrices();
      }
   }, [
      form.getFieldValue('entityCategories'),
      form.getFieldValue('isLoadingRequired')
   ]);

   const normalizePrices = () => {
      const cats = form.getFieldValue('entityCategories');
      const priceList = [];

      cats.forEach((e) => {
         if (e?.materialCategoryId && e?.capacity) {
            priceList.push({
               categoryId: e.materialCategoryId,
               loading: !!form.getFieldValue('isLoadingRequired') ? 1 : 0,
               capacity: e?.capacity,
               isFree: !!e?.isFree
            });
         }
      });

      const promises = priceList.map((e) => {
         return getEntityCategoryPrice(e.categoryId, {
            loading: e.loading,
            capacity: e.capacity
         });
      });

      Promise.all(promises).then((res) => {
         const newCategories = cats.map((e, index) => {
            const price = res[index]?.json.price || 0.0;
            return {
               ...e,
               ...res[index]?.json,
               price: !!e?.isFree === true ? 0.0 : price
            };
         });

         if (!_.isEqual(newCategories, pricedEntity)) {
            form.setFieldsValue({
               entityCategories: newCategories
            });
            setPricedEntitys(newCategories);
         }
      });
   };

   const isGoodCapacity = () => {
      if (pricedEntity.length === 0) {
         return null;
      }

      const summOfCapacity = pricedEntity.reduce((acc, e) => {
         return acc + parseInt(e.capacity);
      }, 0);

      if (summOfCapacity < MIN_CAPACITY) {
         return (
            <Alert
               message="Общий вес сырья должен быть больше 70 кг"
               type="error"
               showIcon
               style={{ marginBottom: '15px' }}
            />
         );
      }

      return null;
   };

   const isPriceHigh =
      parseInt(priceObj.maxPrice) > 0 &&
      parseInt(priceObj.maxPrice) <= parseInt(form.getFieldValue('price'));

   return (
      <div style={{ margin: '20px', maxWidth: '1600px' }}>
         {' '}
         <Title>Создать заявку</Title>
         <YandexMetrika />
         <Row gutter={24}>
            {' '}
            <Col xs={24} span={24} xl={24} xs={24}>
               {' '}
               <div style={contentStyle}>
                  <div>
                     <Form
                        style={{
                           maxWidth: '100%',
                           minWidth: 420
                        }}
                        form={form}
                        initialValues={modifiedInitialValues}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        hideRequiredMark
                        layout="vertical">
                        {() => (
                           <div>
                              {/* <Alert
                                 message="Обращаем ваше внимание, что на каждый вид сырья необходимо разместить новую заявку"
                                 type="info"
                                 showIcon
                                 style={{ marginBottom: '15px' }}
                              /> */}

                              <Row gutter={24}>
                                 <Col span={12} xl={24} xs={24}>
                                    <Form.Item
                                       name="entityCategories"
                                       label="Категории сырья и цены">
                                       <Form.List name="entityCategories">
                                          {(fields, { add, remove }) => (
                                             <>
                                                {fields.map(
                                                   ({ key, name, ...restField }) => (
                                                      <Row
                                                         gutter={24}
                                                         key={`entityCategories${key}`}>
                                                         <Col span={10}>
                                                            <Form.Item
                                                               {...restField}
                                                               name={[
                                                                  name,
                                                                  'materialCategoryId'
                                                               ]}
                                                               rules={[
                                                                  {
                                                                     required: true,
                                                                     message:
                                                                        'Укажите категорию'
                                                                  }
                                                               ]}>
                                                               <Select
                                                                  {...restField}
                                                                  allowClear
                                                                  style={{
                                                                     width: '100%'
                                                                  }}
                                                                  placeholder="Выберите категорию"
                                                                  fieldNames={{
                                                                     label: 'name',
                                                                     value: 'id'
                                                                  }}
                                                                  options={
                                                                     categories
                                                                  }
                                                               />
                                                            </Form.Item>
                                                         </Col>

                                                         <Col span={4}>
                                                            <Form.Item
                                                               {...restField}
                                                               name={[
                                                                  name,
                                                                  'capacity'
                                                               ]}>
                                                               <Input
                                                                  min={0}
                                                                  placeholder="Вес"
                                                                  type="number"
                                                                  addonAfter={
                                                                     <Form.Item
                                                                        noStyle>
                                                                        кг
                                                                     </Form.Item>
                                                                  }
                                                               />
                                                            </Form.Item>
                                                         </Col>

                                                         <Col span={8}>
                                                            <Form.Item
                                                               {...restField}
                                                               name={[
                                                                  name,
                                                                  'price'
                                                               ]}>
                                                               <Input
                                                                  disabled={true}
                                                                  className="price-disabled-input"
                                                                  addonAfter={
                                                                     <Form.Item
                                                                        noStyle>
                                                                        руб
                                                                     </Form.Item>
                                                                  }
                                                                  addonBefore={
                                                                     <Form.Item
                                                                        noStyle>
                                                                        Стоимость
                                                                     </Form.Item>
                                                                  }
                                                               />
                                                            </Form.Item>
                                                            <Form.Item
                                                               {...restField}
                                                               name={[
                                                                  name,
                                                                  'isFree'
                                                               ]}
                                                               style={{
                                                                  margin: 0
                                                               }}
                                                               valuePropName={
                                                                  'checked'
                                                               }>
                                                               <Checkbox
                                                                  style={{
                                                                     position:
                                                                        'relative',
                                                                     top: '-10px'
                                                                  }}>
                                                                  Отдать бесплатно
                                                               </Checkbox>
                                                            </Form.Item>
                                                         </Col>

                                                         <Col span={2}>
                                                            <Button
                                                               style={{
                                                                  float: 'right'
                                                               }}
                                                               danger
                                                               type="primary"
                                                               icon={
                                                                  <DeleteOutlined />
                                                               }
                                                               onClick={() =>
                                                                  remove(name)
                                                               }></Button>
                                                         </Col>

                                                         <Col span={24}>
                                                            {pricedEntity.find(
                                                               (e) =>
                                                                  e.id ==
                                                                  form.getFieldValue(
                                                                     'entityCategories'
                                                                  )[key]
                                                                     ?.materialCategoryId
                                                            )?.message ? (
                                                               <Alert
                                                                  message={
                                                                     pricedEntity.find(
                                                                        (e) =>
                                                                           e.id ==
                                                                           form.getFieldValue(
                                                                              'entityCategories'
                                                                           )[key]
                                                                              ?.materialCategoryId
                                                                     )?.message
                                                                  }
                                                                  type={
                                                                     pricedEntity.find(
                                                                        (e) =>
                                                                           e.id ==
                                                                           form.getFieldValue(
                                                                              'entityCategories'
                                                                           )[key]
                                                                              ?.materialCategoryId
                                                                     )?.errorType
                                                                  }
                                                                  showIcon
                                                                  style={{
                                                                     marginBottom:
                                                                        '15px'
                                                                  }}
                                                               />
                                                            ) : null}
                                                         </Col>
                                                      </Row>
                                                   )
                                                )}{' '}
                                                <Col xs={24} span={24} xl={24}>
                                                   {isGoodCapacity()}
                                                </Col>
                                                <Form.Item>
                                                   <Button
                                                      type="dashed"
                                                      onClick={() => add()}
                                                      block
                                                      style={{ maxWidth: '220px' }}
                                                      icon={<PlusOutlined />}>
                                                      Добавить вторсырье
                                                   </Button>
                                                </Form.Item>
                                             </>
                                          )}
                                       </Form.List>
                                    </Form.Item>
                                 </Col>

                                 <Col xs={24} span={24} xl={24}>
                                    <Form.Item
                                       label={'Нужна ли погрузка'}
                                       name={'isLoadingRequired'}
                                       rules={[
                                          {
                                             required: true,
                                             message: 'Поле пропущено'
                                          }
                                       ]}
                                       key={'isLoadingRequiredform'}>
                                       <Radio.Group name={'isLoadingRequired'}>
                                          <Radio value={true}>Да</Radio>
                                          <Radio value={false}>Нет</Radio>
                                       </Radio.Group>
                                    </Form.Item>
                                 </Col>
                              </Row>

                              {priceObj.textType !== 'error' &&
                              form.getFieldValue('entityCategories') &&
                              form.getFieldValue('isLoadingRequired') !==
                                 undefined ? (
                                 <>
                                    <Row gutter={24}>
                                       <Col xs={24} span={24} xl={24}>
                                          <Form.Item
                                             name="comment"
                                             label="Комментарий">
                                             <TextArea
                                                rows={2}
                                                placeholder="Можете указать, номер подъезда, домофон, переулок"
                                                maxLength={500}
                                             />
                                          </Form.Item>
                                          <Form.Item
                                             name={'typeOfAddress'}
                                             label="Место вывоза"
                                             rules={[
                                                {
                                                   required: true,
                                                   message: 'Укажите место вывоза'
                                                }
                                             ]}>
                                             <Select
                                                style={{
                                                   width: '100%'
                                                }}
                                                maxCount={1}
                                                placeholder="Выберите место вывоза"
                                                options={typeOfAddresses.map(
                                                   (e) => ({
                                                      value: e.value,
                                                      label: e.title
                                                   })
                                                )}
                                             />
                                          </Form.Item>

                                          <Form.Item
                                             label="Полный адрес (город, улица, номер дома/квартиры)"
                                             name="address"
                                             rules={[
                                                {
                                                   required: true,
                                                   message: 'Укажите адрес'
                                                }
                                             ]}>
                                             <SelectAddress allowClear />
                                          </Form.Item>

                                          <Form.Item
                                             name="addressComment"
                                             label="Дополнительно про адрес">
                                             <TextArea
                                                rows={2}
                                                placeholder="Максимум 500 символов"
                                                maxLength={500}
                                             />
                                          </Form.Item>

                                          {Object.values(leadExtraFieldsToExport)
                                             .filter(
                                                (e) =>
                                                   !['isLoadingRequired'].includes(
                                                      e.value
                                                   )
                                             )
                                             .map((e) => (
                                                <Form.Item
                                                   className={
                                                      e.type === 'integer'
                                                         ? ''
                                                         : 'hide-label'
                                                   }
                                                   layout="horizontal"
                                                   label={e.label}
                                                   name={e.value}
                                                   valuePropName={
                                                      e.type === 'integer'
                                                         ? 'value'
                                                         : 'checked'
                                                   }
                                                   key={e.value + e.label + 'form'}>
                                                   {e.type === 'boolean' ? (
                                                      <Checkbox>{e.label}</Checkbox>
                                                   ) : null}

                                                   {e.type === 'integer' ? (
                                                      <Input
                                                         style={{
                                                            width: '190px'
                                                         }}
                                                         placeholder="Введите значение"
                                                         type="number"
                                                      />
                                                   ) : null}
                                                </Form.Item>
                                             ))}

                                          {addressExtraFields.map((e) => (
                                             <Form.Item
                                                className={
                                                   e.type === 'integer'
                                                      ? ''
                                                      : 'hide-label'
                                                }
                                                layout="horizontal"
                                                label={e.label}
                                                name={`address${e.value}`}
                                                valuePropName={
                                                   e.type === 'integer'
                                                      ? 'value'
                                                      : 'checked'
                                                }
                                                key={e.value + e.label + 'form'}>
                                                {e.type === 'boolean' ? (
                                                   <Checkbox>{e.label}</Checkbox>
                                                ) : null}

                                                {e.type === 'integer' ? (
                                                   <Input
                                                      style={{
                                                         width: '190px',
                                                         display: 'inline-block'
                                                      }}
                                                      placeholder="Введите значение"
                                                      type="number"
                                                   />
                                                ) : null}
                                             </Form.Item>
                                          ))}

                                          {/* {Object.values(leadExtraFieldsToExport)
                                             .filter(
                                                (e) =>
                                                   !['isLoadingRequired'].includes(
                                                      e.value
                                                   )
                                             )
                                             .map((e) => (
                                                <Form.Item
                                                   label={e.label}
                                                   name={e.value}
                                                   rules={[
                                                      {
                                                         required: true,
                                                         message: 'Поле пропущено'
                                                      }
                                                   ]}
                                                   key={e.value + e.label + 'form'}>
                                                   {e.type === 'boolean' ? (
                                                      <Radio.Group name={e.value}>
                                                         <Radio value={true}>
                                                            Да
                                                         </Radio>
                                                         <Radio value={false}>
                                                            Нет
                                                         </Radio>
                                                      </Radio.Group>
                                                   ) : null}

                                                   {e.type === 'integer' ? (
                                                      <Input
                                                         style={{
                                                            width: '160px'
                                                         }}
                                                         placeholder="в метрах"
                                                         type="number"
                                                      />
                                                   ) : null}
                                                </Form.Item>
                                             ))} */}
                                       </Col>
                                    </Row>

                                    <Row gutter={16}>
                                       <Col xs={24} span={12} xl={12}>
                                          <Form.Item
                                             name="firstName"
                                             label="Имя"
                                             rules={[
                                                {
                                                   required: true,
                                                   message: 'Поле пропущено'
                                                }
                                             ]}>
                                             <Input
                                                placeholder="Имя"
                                                rules={[
                                                   {
                                                      required: true,
                                                      message: 'Поле пропущено'
                                                   }
                                                ]}
                                             />
                                          </Form.Item>
                                          <Form.Item
                                             name="lastName"
                                             label="Фамилия"
                                             rules={[
                                                {
                                                   required: true,
                                                   message: 'Поле пропущено'
                                                }
                                             ]}>
                                             <Input placeholder="Фамилия" />
                                          </Form.Item>
                                       </Col>
                                       <Col xs={24} span={12} xl={12}>
                                          <Form.Item
                                             name="email"
                                             label="E-mail"
                                             rules={[
                                                {
                                                   type: 'email',
                                                   message: 'Не похоже на почту!'
                                                },
                                                {
                                                   required: true,
                                                   message: 'Поле пропущено'
                                                }
                                             ]}>
                                             <Input placeholder="Почта" />
                                          </Form.Item>
                                          <Form.Item
                                             name="mobileNumber"
                                             label="Телефон"
                                             rules={mobileNumberRequired}>
                                             <Input
                                                addonBefore={
                                                   <Form.Item noStyle>+7</Form.Item>
                                                }
                                                style={{
                                                   width: '100%'
                                                }}
                                                placeholder="Введите телефон"
                                             />
                                          </Form.Item>
                                       </Col>
                                       <Col xs={24} span={24} xl={24}>
                                          <Form.Item label="Даты вывоза">
                                             <Form.List name="leadTimes">
                                                {(fields, { add, remove }) => (
                                                   <>
                                                      {fields.map(
                                                         ({
                                                            key,
                                                            name,
                                                            ...restField
                                                         }) => (
                                                            <Row
                                                               gutter={24}
                                                               key={`leadTimes${key}`}>
                                                               <Col span={10}>
                                                                  <Form.Item
                                                                     {...restField}
                                                                     name={[
                                                                        name,
                                                                        'fromTimestamp'
                                                                     ]}
                                                                     rules={[
                                                                        {
                                                                           required: true,
                                                                           message:
                                                                              'Укажите дату вывоза'
                                                                        }
                                                                     ]}>
                                                                     <DatePicker
                                                                        style={{
                                                                           width: '100%'
                                                                        }}
                                                                        format="DD-MM-YYYY"
                                                                        disabledDate={
                                                                           disabledDate
                                                                        }
                                                                        disabledTime={
                                                                           disabledDateTime
                                                                        }
                                                                     />
                                                                  </Form.Item>{' '}
                                                               </Col>
                                                               <Col span={12}>
                                                                  <Form.Item
                                                                     {...restField}
                                                                     name={[
                                                                        name,
                                                                        'timeArr'
                                                                     ]}
                                                                     rules={[
                                                                        {
                                                                           required: true,
                                                                           message:
                                                                              'Укажите дату вывоза'
                                                                        }
                                                                     ]}>
                                                                     <TimePicker.RangePicker
                                                                        format="HH:mm"
                                                                        placeholder={[
                                                                           'Время с',
                                                                           'Время по'
                                                                        ]}
                                                                        minuteStep="5"
                                                                        showNow
                                                                        style={{
                                                                           width: '100%'
                                                                        }}
                                                                     />
                                                                  </Form.Item>
                                                               </Col>

                                                               <Col span={2}>
                                                                  <Button
                                                                     style={{
                                                                        float: 'right',
                                                                        marginBottom:
                                                                           '20px'
                                                                     }}
                                                                     danger
                                                                     type="primary"
                                                                     icon={
                                                                        <DeleteOutlined />
                                                                     }
                                                                     onClick={() =>
                                                                        remove(name)
                                                                     }></Button>
                                                               </Col>
                                                            </Row>
                                                         )
                                                      )}{' '}
                                                      <Form.Item>
                                                         <Button
                                                            type="dashed"
                                                            onClick={() => add()}
                                                            block
                                                            style={{
                                                               maxWidth: '220px'
                                                            }}
                                                            icon={<PlusOutlined />}>
                                                            Добавить дату вывоза
                                                         </Button>
                                                      </Form.Item>
                                                   </>
                                                )}
                                             </Form.List>
                                          </Form.Item>
                                       </Col>{' '}
                                       <Col
                                          xs={24}
                                          className="gutter-row"
                                          span={24}
                                          xl={24}>
                                          <Form.Item>
                                             <Button
                                                type="primary"
                                                htmlType="submit"
                                                loading={isLoading}>
                                                Создать
                                             </Button>
                                          </Form.Item>
                                       </Col>
                                       <Row gutter={[16, 24]}>
                                          {errorMessage && (
                                             <Col
                                                xs={24}
                                                className="gutter-row"
                                                span={24}>
                                                <Alert
                                                   message={errorMessage.replace(
                                                      'Error: ',
                                                      ''
                                                   )}
                                                   type="error"
                                                   style={{ marginBottom: '20px' }}
                                                   showIcon
                                                />
                                             </Col>
                                          )}
                                       </Row>
                                    </Row>
                                 </>
                              ) : null}
                           </div>
                        )}
                     </Form>{' '}
                  </div>
               </div>{' '}
            </Col>
         </Row>
      </div>
   );
};

export default CreateLeadPublic;

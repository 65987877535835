import React, { useState, useEffect } from 'react';
import StoryForm from './StoryForm';
import { CreateStory } from '../model/CreateStory';
import { UpdateStory } from '../model/UpdateStory';
import { GetStory } from '../model/GetStory';
import { generateUUID } from '@shared/utils/default';
import _ from 'lodash';
import { message } from 'antd';

const CreateOrEditStory = ({ id = null, callbackOnSuccess = () => {} }) => {
   const isEditForm = !!id;
   const [initialValues, setIntialValues] = useState({});
   const [loadForm, setLoadForm] = useState(false);
   const [errorMessage, setErrorMessage] = useState('');

   useEffect(() => {
      if (!isEditForm) {
         return;
      }
      fetchData();

      return () => {
         setErrorMessage('');
         setLoadForm(false);
      };
   }, [id]);

   const fetchData = () => {
      setLoadForm(false);
      GetStory(id).then((res) => {
         setIntialValues({ ...initialValues, ...res });
         setLoadForm(true);
      });
   };

   const onSuccess = async (values, setLoading) => {
      setLoading(true);
      values = {
         ...values
      };

      if (values.tag === '' || !values.tag || values.tag === null) {
         values.tag = generateUUID();
      }

      if (isEditForm) {
         const noteId = initialValues.id;

         const mergedObj = { ...initialValues, ...values };
         const updatedFields = _.pickBy(
            mergedObj,
            (v, k) => !_.isEqual(initialValues[k], v)
         );

         await UpdateStory(updatedFields, noteId)
            .then(() => {
               callbackOnSuccess();
               message.success('История изменена');
            })
            .catch((e) => {
               fetchData();
               const errMessage = e?.message || 'Возникла ошибка при сохранении';
               setErrorMessage(errMessage);
               message.error(errMessage);
            })
            .finally(() => setLoading(false));

         return;
      }

      await CreateStory(values)
         .then(() => {
            callbackOnSuccess();
            message.success('История создана');
         })
         .catch((e) => {
            const errMessage = e?.message || 'Заметка не создана, возникла ошибка';
            setErrorMessage(errMessage);
            message.error(errMessage);
         })
         .finally(() => setLoading(false));
   };

   if (!loadForm && isEditForm) {
      // Чтобы initialValues прогрузились при первом рендере, если это Редактируемая форма
      return <></>;
   }

   return (
      <StoryForm
         errorMessage={errorMessage}
         initialValues={initialValues}
         isEditForm={isEditForm}
         onSuccess={onSuccess}
      />
   );
};

export default CreateOrEditStory;
